import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionType } from '../models/enums';
import { NotificationMessageInterface } from '../models/interfaces';
import { SecurityService } from '../services/auth';
import { StorageService } from '../services/storage';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanLoad, CanActivate {

  public constructor(private security: SecurityService, private router: Router, private storageService: StorageService) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.security.isAllowed(this.isOfType(state.url))) {
      return true;
    }

    const notification: NotificationMessageInterface = {
      type: 'error',
      message: 'This action is unauthorized.'
    };

    this.storageService.set('notification', JSON.stringify(notification));

    this.router.navigateByUrl('/index');

    return false;
  }

  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    if (this.security.isAllowed(this.identifyPermissionTypeFromURL(segments))) {
      return true;
    }

    const notification: NotificationMessageInterface = {
      type: 'error',
      message: 'This action is unauthorized.'
    };

    this.storageService.set('notification', JSON.stringify(notification));

    this.router.navigateByUrl('/index');

    return false;
  }

  protected identifyPermissionTypeFromURL(segments: UrlSegment[]): PermissionType {
    return this.isOfType(this.combineUrlSegments(segments));
  }

  protected combineUrlSegments(segments: UrlSegment[]): string {
    let uri = '';

    segments.forEach((segment: UrlSegment) => {
      if (uri.length === 0) {
        uri = segment.path;
      } else {
        uri = `${uri}/${segment.path}`;
      }
    });

    return uri;
  }

  protected isOfType(uri: string): PermissionType {
    if (uri.includes('details')) {
      return PermissionType.VIEW;
    }

    if (uri.includes('add')) {
      return PermissionType.ADD;
    }

    if (uri.includes('edit')) {
      return PermissionType.EDIT;
    }

    if (uri.includes('backups')) {
      return PermissionType.EXPORT;
    }

    // todo
    if (uri.includes('hard-delete')) {
      return PermissionType.HARD_DELETE;
    }

    // todo
    if (uri.includes('manage-hierarchy')) {
      return PermissionType.MANAGE_HIERARCHY;
    }

    if (uri.includes('admin/users') || uri.includes('my-account') || uri.includes('usage-report')) {
      return PermissionType.MANAGE_USERS;
    }

    // todo
    if (uri.includes('restore')) {
      return PermissionType.RESTORE;
    }

    // todo
    if (uri.includes('soft-delete')) {
      return PermissionType.SOFT_DELETE;
    }

    // todo
    if (uri.includes('upload')) {
      return PermissionType.UPLOAD;
    }

    return PermissionType.VIEW;
  }

}
