import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'rap2-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  public title = 'RAP 2.0';

  public ngOnInit(): void {

  }

  public ngAfterViewInit(): void {
    this.toggleMenus();
  }

  protected toggleMenus(): void {
    document.querySelector('body').addEventListener('click', () => {
      const dropdownElement = document.querySelectorAll('.dropdown-menu.show');

      dropdownElement.forEach((elem: HTMLElement) => {
        const toggleButton: HTMLElement = elem.parentElement.parentElement.parentElement.querySelector('.menu-toggle-button');

        if (toggleButton) {
          toggleButton.click();
        }

        const closeButton: HTMLElement = elem.querySelector('.close-button');

        if (closeButton) {
          closeButton.click();
        }
      });
    });
  }
}
