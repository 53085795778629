import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public get(key: string): string | null {
    return localStorage.getItem(key);
  }

  public set(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  public has(key: string): boolean {
    const item = this.get(key);

    return item !== null && item !== '';
  }

  public clearAll(): void {
    const seenAnnouncement = this.get('announcement-seen');

    localStorage.clear();

    this.set('announcement-seen', '1');
  }

  public remove(key: string): void {
    localStorage.removeItem(key);
  }

}
