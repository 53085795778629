import { Injectable } from '@angular/core';
import { Token } from '../../models';
import { StorageService } from '../storage';

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  constructor(protected storage: StorageService) {
  }

  public setToken(token: Token): void {
    this.storage.set('token', JSON.stringify(token));
  }

  public getToken(): Token {
    return <Token>JSON.parse(this.storage.get('token'));
  }
}
