import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SecurityService } from '../services/auth';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {

  public constructor(private security: SecurityService, private router: Router) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.security.isLoggedIn()) {
      return true;
    }

    this.security.logout(this.router);

    return false;
  }

  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    if (this.security.isLoggedIn()) {
      return true;
    }

    this.security.logout(this.router);

    return false;
  }

}
