import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HierarchyItem, Token } from '../models';
import { JwtService } from '../services/auth';
import { StorageService } from '../services/storage';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    protected jwtService: JwtService,
    protected storageService: StorageService
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {
      'Access-Control-Allow-Origin': '*'
    };

    const token: Token = this.jwtService.getToken();

    if (token) {
      headersConfig['Authorization'] = `Bearer ${token.value}`;
    }

    let params = {};

    const withTrashed = JSON.parse(this.storageService.get('with-trashed')) as boolean;
    const currentHierarchy = JSON.parse(this.storageService.get('current-hierarchy-item')) as HierarchyItem;

    if (withTrashed && currentHierarchy && currentHierarchy.is_deleted) {
      params = {
        with_trashed: 'true'
      };
    }

    if (
      (req.url === `${environment.baseUrl}/api/hierarchies` || req.url === `${environment.baseUrl}/api/hierarchies?group_by=state`)
      && withTrashed
    ) {
      params = {
        with_trashed: 'true'
      };
    }

    const request = req.clone({setHeaders: headersConfig, setParams: params});

    return next.handle(request);
  }

}
