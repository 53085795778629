import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ComponentCanDeactivate } from '../components/ComponentCanDeactivate';
import { PageCanDeactivateInterface } from '../models/interfaces/page-can-deactivate.interface';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {

  public canDeactivate(component: ComponentCanDeactivate | PageCanDeactivateInterface): boolean {
    if (!component.canDeactivate()) {
      if (confirm('You have unsaved changes! If you leave, your changes will be lost.')) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  }

}
