import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ResponseInterface, Token, User } from '../../models';
import { PermissionType } from '../../models/enums';
import { StorageService } from '../storage';
import { JwtService } from './jwt.service';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  protected jwtService: JwtService;

  constructor(private storage: StorageService) {
    this.jwtService = new JwtService(storage);
  }

  public isLoggedIn(): boolean {
    const token: Token = this.jwtService.getToken();

    return (token !== undefined && token !== null)
      && !this.isTokenExpired();
  }

  public isAuthenticated(): boolean {
    return this.isLoggedIn();
  }

  public isAllowed(permission: PermissionType): boolean {
    const user: User = this.getCurrentLoggedInUser();

    if (!user.permissions) {
      return false;
    }

    return (user.permissions[permission]) ? user.permissions[permission] : false;
  }

  public getCurrentLoggedInUser(): User {
    return JSON.parse(this.storage.get('user'));
  }

  public isTokenExpired(): boolean {
    const loginTime: {original: Date, updated: Date} = {
      original: null,
      updated: null
    };

    const session = JSON.parse(this.storage.get('loginTime'));

    if (!session) {
      return true;
    }

    loginTime.original = new Date(session.original);
    loginTime.updated = new Date(session.updated);

    const currentTime = new Date();
    const diffInSeconds: number = (currentTime.getTime() - loginTime.updated.getTime()) / 1000;
    const token: Token = this.jwtService.getToken();

    return diffInSeconds >= token.expires_in;
  }

  public forceLogout(): void {
    this.logout();
  }

  public logout(router?: Router): void {
    this.processRememberMe();

    if (router) {
      router.navigateByUrl('/auth/login');

      return;
    }

    window.location.href = '/auth/login';
  }

  protected processRememberMe(): void {
    const email = this.storage.get('_ue-r');
    const password = this.storage.get('_up-r');
    const rememberMe: boolean = JSON.parse(this.storage.get('_c-r'));

    this.storage.clearAll();

    if (rememberMe) {
      this.storage.set('_ue-r', email);
      this.storage.set('_up-r', password);
      this.storage.set('_c-r', JSON.stringify(rememberMe));
    }
  }

  public isTokenExpiring(): boolean {
    const loginTime: {original: Date, updated: Date} = {
      original: null,
      updated: null
    };

    const session = JSON.parse(this.storage.get('loginTime'));

    if (!session) {
      return true;
    }

    loginTime.original = new Date(session.original);
    loginTime.updated = new Date(session.updated);

    const currentTime = new Date();
    const diffInSeconds: number = (currentTime.getTime() - loginTime.updated.getTime()) / 1000;
    const token: Token = this.jwtService.getToken();

    return (diffInSeconds + 600) >= token.expires_in;
  }

}
