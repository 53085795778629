export enum PermissionType {
  ADD = 'add',
  EDIT = 'edit',
  EXPORT = 'export',
  HARD_DELETE = 'hard-delete',
  MANAGE_HIERARCHY = 'manage-hierarchy',
  MANAGE_USERS = 'manage-users',
  RESTORE = 'restore',
  SOFT_DELETE = 'soft-delete',
  UPLOAD = 'upload',
  VIEW = 'view'
}
