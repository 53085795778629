import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard, CanDeactivateGuard } from './shared/guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'index',
    loadChildren: () => import('./modules/main-screen/main-screen.module').then(m => m.MainScreenModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'property',
    loadChildren: () => import('./modules/property/property.module').then(m => m.PropertyModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'contacts',
    loadChildren: () => import('./modules/contacts/contacts.module').then(m => m.ContactsModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'plans',
    loadChildren: () => import('./modules/plans/plans.module').then(m => m.PlansModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'documents',
    loadChildren: () => import('./modules/documents/documents.module').then(m => m.DocumentsModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'photos',
    loadChildren: () => import('./modules/photos/photos.module').then(m => m.PhotosModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'pages',
    loadChildren: () => import('./modules/static-pages/static-pages.module').then(m => m.StaticPagesModule)
  },
  {
    path: '**',
    redirectTo: 'pages/error/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes , {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule],
  providers: [AuthGuard, CanDeactivateGuard]
})
export class AppRoutingModule {
}
